import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import styled from "styled-components"


const Wrapper = styled.section`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    a {
        display: block;
        margin-top: 8px;
        transition: .2s;
        &:hover {
            color: #8b8585;
            transition: .2s;
        }
    }
`;


export default ({data}) => {
    const contacts = data.contentfulContacts;
    function phoneNormalize(phone) {
        let normalizedPhone = phone[0];
        
        normalizedPhone = normalizedPhone + '39' + ' (' + phone.slice(3,6) + ') ' + phone.slice(6,9) + ' ' + phone.slice(9,11) + ' ' + phone.slice(11,13);
        return normalizedPhone;
    }
    return (
        <Layout>
            <Seo 
                title ={contacts.title}
            />
            <Wrapper>
                <h1 className="visually-hidden">{contacts.title}</h1>
                <a href={`tel:${contacts.phone}`}>{phoneNormalize(contacts.phone)}</a>
                <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
            </Wrapper>
        </Layout>
    )
}

export const contactsQuery = graphql `
    query contactsQuery {
        contentfulContacts {
            title
            phone
            email
        }
    }
`